import { useAbortController } from "@hooks/useAbortController";
import type { ApiErrorResponse } from "@t/server";
import type { AuthData, UserObject } from "@t/server/Responses";
import { extractApiError } from "@utils/axios";
import { getLocalStorage, setLocalStorage } from "@utils/storage";
import { replaceLastSegmentWith } from "@utils/string";
import { generateToastWrapper } from "@utils/toast";
import type { AxiosInstance, CreateAxiosDefaults } from "axios";
import axios, { HttpStatusCode, isAxiosError, isCancel } from "axios";
import type { AxiosAuthRefreshRequestConfig } from "axios-auth-refresh";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { jwtDecode } from "jwt-decode";
import type { PropsWithChildren } from "react";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { toast } from "react-toastify";

const errorCodesToRefreshToken = [401];

export type AuthContextType = {
    hasSubscription: boolean;
    checkSubscription: (callback?: (hasSub: boolean) => void) => void;
    axios: AxiosInstance;
    login: (
        username: string,
        password: string,
        cloudflareToken: string,
        tfaCode?: string,
        recoveryCode?: string,
    ) => Promise<boolean>;
    logout: () => Promise<void>;
    user: null | UserObject;
    loggedIn: boolean;
    updateToken: (token: string) => void;
    userHasPermission: (claim: string | string[]) => boolean;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

// Hook for this context
export const useAuthContext = () => useContext(AuthContext);

export const axiosDefaults: CreateAxiosDefaults = {
    baseURL: "https://beta.gingsystem.com/api/",
    // withCredentials: true,
    headers: {
        "Content-Type": "application/json",
    },
};

const AxiosProvider = ({ children }: PropsWithChildren) => {
    const permissionAbortController = useAbortController();

    const [token, setToken] = useState<string | null>(() =>
        getLocalStorage("token", null),
    );

    const [permissions, setPermissions] = useState<string[]>(() =>
        getLocalStorage("permissions", []),
    );

    const [hasSubscription, setHasSubscription] = useState<boolean>(true);

    const user = token ? jwtDecode<UserObject>(token) : null;
    const isLoggedIn = user !== null;

    const ourAxios = axios.create(axiosDefaults);

    // Removed the useCallback function:
    /*
However, since the login function does not rely on any props or state, we could also skip using useCallback without any noticeable performance issues.
    */
    const loginFunc = async (
        username: string,
        password: string,
        cloudflareToken: string,
        tfaCode?: string,
        recoveryCode?: string,
    ): Promise<boolean> => {
        if (isLoggedIn) return Promise.resolve(true);

        try {
            const response = await ourAxios.post<AuthData>(
                "/auth/login",
                {
                    email: username,
                    password,
                    cloudflareToken,
                    twofactor: tfaCode,
                    recoveryCode,
                    deviceId: import.meta.env.VITE_APP_ID,
                },
                {
                    withCredentials: true,
                    skipAuthRefresh: true,
                } as AxiosAuthRefreshRequestConfig,
            ); // I think withCreds is needed to send/recieve cookies

            updateToken(response.data.token);

            return Promise.resolve(true);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    const logoutFunc = async () => {
        try {
            ourAxios.get("/auth/logout", { withCredentials: true }); // withCreds so we get our cookie overidden!
        } catch (err) {
            // Noop. We don't really care if this fails as it just removes the cookie. If this doesn't happen it's not the end of the world.
            // If it does fail though, we still need to update the user and remove the localstorage
        }

        toast.success(generateToastWrapper("Logged out!"));

        localStorage.removeItem("permissions");

        //setUser(() => null); // Remove token from localstorage. Hopefully the call above will have removed the refresh token!
        updateToken(null);

        return Promise.resolve();
    };

    const checkSubscription = async (callback?: (hasSub: boolean) => void) => {
        try {
            const resp = await ourAxios.get("/company/subscription-check", {
                signal: permissionAbortController.getAbortController().signal,
            });
            setHasSubscription(resp.status != HttpStatusCode.PaymentRequired);
            callback && callback(resp.status != HttpStatusCode.PaymentRequired);
        } catch (err) {
            if (!isCancel(err) && isAxiosError(err)) {
                setHasSubscription(
                    err.response?.status != HttpStatusCode.PaymentRequired,
                );
                callback &&
                    callback(
                        err.response?.status != HttpStatusCode.PaymentRequired,
                    );
            }
        }
    };

    const refreshPermissions = async () => {
        if (getLocalStorage("token", null)) {
            try {
                const resp = await ourAxios.get<string[]>(
                    "/account/permissions",
                    {
                        signal: permissionAbortController.getAbortController()
                            .signal,
                    },
                );

                setPermissions(resp.data);
                setLocalStorage("permissions", resp.data);
            } catch (err) {
                if (!isCancel(err) && isAxiosError<ApiErrorResponse>(err)) {
                    toast.error(
                        generateToastWrapper(
                            "Error getting permissions",
                            extractApiError(err),
                        ),
                    );
                }
            }
        }
    };

    const updateToken = (token: string | null) => {
        setToken(() => token);

        token
            ? setLocalStorage("token", token)
            : localStorage.removeItem("token");

        token && refreshPermissions();
        token && checkSubscription();
    };

    ourAxios.interceptors.request.use((request) => {
        if (getLocalStorage("token", "") !== "") {
            request.headers["Authorization"] = `Bearer ${getLocalStorage(
                "token",
                "",
            )}`;
        }
        return request;
    });
    ourAxios.interceptors.response.use(
        (res) => res,
        async (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status ==
                        HttpStatusCode.InternalServerError &&
                    !err.response?.data
                ) {
                    toast.error(
                        generateToastWrapper(
                            "Error connecting to api",
                            "Looks like there was an error trying to connect to the api. Please try again later or let us know if this is persistient.",
                        ),
                    );
                }

                if (err.response?.status == HttpStatusCode.PaymentRequired) {
                    // Payment required... We should mark the user as not having a subscription so we can show the payment page (if they have permission)
                    setHasSubscription(false);
                }

                // Check if we requested a blob. If so, we want to transform the blob back into a json object that we can actually use
                if (
                    err.request?.responseType === "blob" &&
                    err.response &&
                    "data" in err.response &&
                    err.response.data instanceof Blob &&
                    err.response.data.type &&
                    err.response.data.type.toLowerCase().indexOf("json") != -1
                ) {
                    err.response.data = JSON.parse(
                        await err.response?.data.text(),
                    );
                }
            }

            // Rethrow. Otherwise, the response will be successfull when using axios.post(). Which, we don't want. We want it to ERRORRRRR
            throw err;
        },
    );

    const tokenRequester = axios.create({
        ...axiosDefaults,
        withCredentials: true,
    });

    tokenRequester.interceptors.response.use(
        (res) => res,
        (err) => {
            if (isAxiosError<ApiErrorResponse>(err)) {
                toast.warning(
                    generateToastWrapper(
                        "Error auto-refreshing token",
                        extractApiError(err),
                    ),
                );
            }

            logoutFunc();
            return err;
        },
    );

    createAuthRefreshInterceptor(
        ourAxios,
        (failedRequest) => {
            return tokenRequester
                .get<AuthData>("/auth/refreshToken")
                .then((tokenRefreshResponse) => {
                    // So that the interceptors above has the new token immedietly (setToken is async)
                    setLocalStorage("token", tokenRefreshResponse.data.token);

                    updateToken(tokenRefreshResponse.data.token);

                    failedRequest.response.config.headers["Authorization"] =
                        `Bearer ${tokenRefreshResponse.data.token}`;
                    return Promise.resolve();
                })
                .catch((e) => Promise.reject(e));
        },
        {
            statusCodes: [HttpStatusCode.Unauthorized],
            // Only refresh if we have a token!
            shouldRefresh: (error) => {
                if (!error) return false;
                if (!error.response) return false;

                if (!("x-token-expired" in error.response.headers))
                    return false;

                const url = error.config?.url?.toLowerCase();
                if (
                    url?.includes("/auth/login") ||
                    url?.includes("/auth/logout")
                ) {
                    // Do not attempt to refresh token for login/logout requests
                    return false;
                }

                if (errorCodesToRefreshToken.includes(error.response.status)) {
                    return getLocalStorage("token", null) !== null;
                }

                return false;
            },
        },
    );

    const userHasPermission = useCallback(
        (claims: string | string[]): boolean => {
            if (!isLoggedIn) return false;
            if (permissions.length == 0) return false;
            if (user?.primarygroupsid == null) return false;

            const allClaims =
                typeof claims == "string" ? [claims] : [...claims];

            for (const claim of [...allClaims]) {
                const allClaim = replaceLastSegmentWith(claim, "*");
                if (!allClaims.includes(allClaim)) {
                    allClaims.push(allClaim);
                }
            }

            const checks = allClaims.map((claim) =>
                permissions.includes(claim),
            );
            return checks.some((x) => x);
        },
        [permissions, user?.primarygroupsid, isLoggedIn],
    );

    // On initial load, we want to refresh the permissions
    useEffect(() => {
        if (isLoggedIn) {
            refreshPermissions();
            checkSubscription();
        }

        return () => {
            permissionAbortController.resetAbortController();
        };
    }, [isLoggedIn]);

    return (
        <AuthContext.Provider
            value={{
                axios: ourAxios,
                login: loginFunc,
                logout: logoutFunc,
                user,
                updateToken,
                loggedIn: isLoggedIn,
                userHasPermission,
                hasSubscription,
                checkSubscription,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AxiosProvider;
