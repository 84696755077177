import AuthWrapper from "@components/Routes/AuthWrapper";

import PermissionWrapper from "@components/Routes/PermissionWrapper";
import { Claims } from "@t/client";
import { lazy } from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";

const MainPages = {
    layout: lazy(() => import("@components/Layouts/MainLayout")),
    error404: lazy(() => import("@components/Pages/Error404Page")),
    errorPermission: lazy(
        () => import("@components/Pages/PermissionErrorPage"),
    ),
    auth: {
        layout: lazy(() => import("@components/Layouts/auth/AuthLayout")),
        logout: lazy(() => import("@components/Pages/auth/Logout")),
        login: lazy(() => import("@components/Pages/auth/Login")),
        register: lazy(() => import("@components/Pages/auth/Register")),
        confirmEmail: lazy(() => import("@components/Pages/auth/ConfirmEmail")),
        forgotPassword: lazy(
            () => import("@components/Pages/auth/ForgottenPassword"),
        ),
        refreshToken: lazy(() => import("@components/Routes/RefreshToken")),
    },
    entityPage: lazy(() => import("@components/Pages/SharedEntityPage")),
};

const DashboardPages = {
    layout: lazy(() => import("@components/Layouts/dashboard/DashboardLayout")),
    index: lazy(() => import("@components/Pages/dashboard/DashboardIndex")),
    sharedEntities: {
        index: lazy(
            () =>
                import("@components/Pages/dashboard/entities/SharedEntityView"),
        ),
    },
    baseMaterials: {
        index: lazy(
            () =>
                import(
                    "@components/Pages/dashboard/entities/DashboardBaseMaterials"
                ),
        ),
        view: lazy(
            () =>
                import(
                    "@components/Pages/dashboard/entities/itemViews/BaseMaterialView"
                ),
        ),
        bulkUpload: lazy(
            () =>
                import(
                    "@components/Pages/dashboard/entities/DashboardBaseMaterialsBulkUpload"
                ),
        ),
    },
    materials: {
        index: lazy(
            () =>
                import(
                    "@components/Pages/dashboard/entities/DashboardMaterials"
                ),
        ),
        view: lazy(
            () =>
                import(
                    "@components/Pages/dashboard/entities/itemViews/MaterialView"
                ),
        ),
        bulkUpload: lazy(
            () =>
                import(
                    "@components/Pages/dashboard/entities/DashboardMaterialsBulkUpload"
                ),
        ),
    },
    components: {
        index: lazy(
            () =>
                import(
                    "@components/Pages/dashboard/entities/DashboardComponents"
                ),
        ),
        view: lazy(
            () =>
                import(
                    "@components/Pages/dashboard/entities/itemViews/ComponentView"
                ),
        ),
        bulkUpload: lazy(
            () =>
                import(
                    "@components/Pages/dashboard/entities/DashboardComponentsBulkUploads"
                ),
        ),
    },
    completePackaging: {
        index: lazy(
            () =>
                import(
                    "@components/Pages/dashboard/entities/DashboardCompletePackaging"
                ),
        ),
        view: lazy(
            () =>
                import(
                    "@components/Pages/dashboard/entities/itemViews/CompletePackageView"
                ),
        ),
        bulkUpload: lazy(
            () =>
                import(
                    "@components/Pages/dashboard/entities/DashboardCompletePackagingBulkUploads"
                ),
        ),
    },
    multipacks: {
        index: lazy(
            () =>
                import(
                    "@components/Pages/dashboard/entities/DashboardMultipacks"
                ),
        ),
        view: lazy(
            () =>
                import(
                    "@components/Pages/dashboard/entities/itemViews/MultipackView"
                ),
        ),
        bulkUpload: lazy(
            () =>
                import(
                    "@components/Pages/dashboard/entities/DashboardMultipacksBulkUpload"
                ),
        ),
    },
    loads: {
        index: lazy(
            () => import("@components/Pages/dashboard/entities/DashboardLoads"),
        ),
        view: lazy(
            () =>
                import(
                    "@components/Pages/dashboard/entities/itemViews/LoadView"
                ),
        ),
        bulkUpload: lazy(
            () =>
                import(
                    "@components/Pages/dashboard/entities/DashboardLoadsBulkUpload"
                ),
        ),
    },
    settings: {
        account: lazy(
            () =>
                import("@components/Pages/dashboard/settings/AccountSettings"),
        ),
        security: lazy(
            () =>
                import("@components/Pages/dashboard/settings/SecuritySettings"),
        ),
    },
    organisation: {
        details: lazy(
            () =>
                import(
                    "@components/Pages/dashboard/organisation/DashboardOrganisation"
                ),
        ),
        team: lazy(
            () =>
                import(
                    "@components/Pages/dashboard/organisation/DashboardTeam"
                ),
        ),
        supplyChain: lazy(
            () =>
                import(
                    "@components/Pages/dashboard/organisation/DashboardSupplyChain"
                ),
        ),
        dataSharingSettings: lazy(
            () =>
                import(
                    "@components/Pages/dashboard/organisation/DataSharingSettings"
                ),
        ),
    },
};

const loginRedirect = "/auth/login";

const AppRoutes = createBrowserRouter([
    // Define our routes in here!
    {
        path: "/",
        element: <MainPages.layout />,
        children: [
            {
                path: "*", // Catch all
                element: <MainPages.error404 />,
            },
            {
                path: "not-found", // Just in case we change the catch all
                element: <MainPages.error404 />,
            },
            {
                path: "permission-required",
                element: <MainPages.errorPermission />,
            },
            {
                element: <MainPages.auth.layout />,
                children: [
                    {
                        element: <MainPages.auth.login />,
                        index: true,
                    },
                ],
            },
            {
                path: "/v/:uid",
                element: <MainPages.entityPage />,
            },
            {
                path: "/auth",
                element: <MainPages.auth.layout />,
                children: [
                    {
                        path: "login",
                        element: <MainPages.auth.login />,
                    },
                    {
                        path: "logout",
                        element: <MainPages.auth.logout />,
                    },
                    {
                        path: "register",
                        element: <MainPages.auth.register />,
                    },
                    {
                        path: "confirm-email",
                        element: <MainPages.auth.confirmEmail />,
                    },
                    {
                        path: "forgotten-password",
                        element: <MainPages.auth.forgotPassword />,
                    },
                    {
                        path: "refresh-token",
                        element: (
                            <MainPages.auth.refreshToken to={"/dashboard"} />
                        ),
                    },
                ],
            },
            {
                path: "/payment",
                children: [
                    {
                        path: "*",
                        element: <Navigate to="/dashboard" replace={true} />,
                    },
                ],
                // Just redirect to the dashboard using react-router-dom's redirect
                element: <Navigate to="/dashboard" replace={true} />,
            },
        ],
    },
    {
        path: "/dashboard",
        element: (
            // Note: The dashboard layout shoves the "onboarding page" down peoples throats until they're in a organisation
            <AuthWrapper
                redirectPath={loginRedirect}
                outlet={<DashboardPages.layout />}
            />
        ),
        children: [
            {
                index: true,
                element: <DashboardPages.index />,
            },
            {
                path: "base-materials",
                children: [
                    {
                        index: true,
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.BaseMaterial.Read]}
                                outlet={<DashboardPages.baseMaterials.index />}
                            />
                        ),
                    },
                    {
                        path: "bulk-import",
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.BaseMaterial.Create]}
                                outlet={
                                    <DashboardPages.baseMaterials.bulkUpload />
                                }
                            />
                        ),
                    },
                    {
                        path: "shared/:uid",
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.BaseMaterial.Read]}
                                outlet={
                                    <DashboardPages.sharedEntities.index
                                        entityType={"base_materials"}
                                    />
                                }
                            />
                        ),
                    },
                    {
                        path: ":id",
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.BaseMaterial.Read]}
                                outlet={<DashboardPages.baseMaterials.view />}
                            />
                        ),
                    },
                ],
            },
            {
                path: "materials",
                children: [
                    {
                        index: true,
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.CombinedMaterial.Read]}
                                outlet={<DashboardPages.materials.index />}
                            />
                        ),
                    },
                    {
                        path: "bulk-import",
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.CombinedMaterial.Create]}
                                outlet={<DashboardPages.materials.bulkUpload />}
                            />
                        ),
                    },
                    {
                        path: "shared/:uid",
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.CombinedMaterial.Read]}
                                outlet={
                                    <DashboardPages.sharedEntities.index
                                        entityType={"materials"}
                                    />
                                }
                            />
                        ),
                    },
                    {
                        path: ":id",
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.CombinedMaterial.Read]}
                                outlet={<DashboardPages.materials.view />}
                            />
                        ),
                    },
                ],
            },
            {
                path: "components",
                children: [
                    {
                        index: true,
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.Components.Read]}
                                outlet={<DashboardPages.components.index />}
                            />
                        ),
                    },
                    {
                        path: "bulk-import",
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.Components.Create]}
                                outlet={
                                    <DashboardPages.components.bulkUpload />
                                }
                            />
                        ),
                    },
                    {
                        path: "shared/:uid",
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.Components.Read]}
                                outlet={
                                    <DashboardPages.sharedEntities.index
                                        entityType={"components"}
                                    />
                                }
                            />
                        ),
                    },
                    {
                        path: ":id",
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.Components.Read]}
                                outlet={<DashboardPages.components.view />}
                            />
                        ),
                    },
                ],
            },
            {
                path: "complete-packaging",
                children: [
                    {
                        index: true,
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.CompletePackaging.Read]}
                                outlet={
                                    <DashboardPages.completePackaging.index />
                                }
                            />
                        ),
                    },
                    {
                        path: "bulk-import",
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.CompletePackaging.Create]}
                                outlet={
                                    <DashboardPages.completePackaging.bulkUpload />
                                }
                            />
                        ),
                    },
                    {
                        path: "shared/:uid",
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.CompletePackaging.Read]}
                                outlet={
                                    <DashboardPages.sharedEntities.index
                                        entityType={"complete_packages"}
                                    />
                                }
                            />
                        ),
                    },
                    {
                        path: ":id",
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.CompletePackaging.Read]}
                                outlet={
                                    <DashboardPages.completePackaging.view />
                                }
                            />
                        ),
                    },
                ],
            },
            {
                path: "multipacks",
                children: [
                    {
                        index: true,
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.Multipack.Read]}
                                outlet={<DashboardPages.multipacks.index />}
                            />
                        ),
                    },
                    {
                        path: "bulk-import",
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.Multipack.Create]}
                                outlet={
                                    <DashboardPages.multipacks.bulkUpload />
                                }
                            />
                        ),
                    },
                    {
                        path: "shared/:uid",
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.Multipack.Read]}
                                outlet={
                                    <DashboardPages.sharedEntities.index
                                        entityType={"multipacks"}
                                    />
                                }
                            />
                        ),
                    },
                    {
                        path: ":id",
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.Multipack.Read]}
                                outlet={<DashboardPages.multipacks.view />}
                            />
                        ),
                    },
                ],
            },
            {
                path: "loads",
                children: [
                    {
                        index: true,
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.Load.Read]}
                                outlet={<DashboardPages.loads.index />}
                            />
                        ),
                    },
                    {
                        path: "bulk-import",
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.Load.Create]}
                                outlet={<DashboardPages.loads.bulkUpload />}
                            />
                        ),
                    },
                    {
                        path: "shared/:uid",
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.Load.Read]}
                                outlet={
                                    <DashboardPages.sharedEntities.index
                                        entityType={"loads"}
                                    />
                                }
                            />
                        ),
                    },
                    {
                        path: ":id",
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.Load.Read]}
                                outlet={<DashboardPages.loads.view />}
                            />
                        ),
                    },
                ],
            },
            {
                path: "settings",
                children: [
                    {
                        path: "account",
                        element: <DashboardPages.settings.account />,
                    },
                    {
                        path: "security",
                        element: <DashboardPages.settings.security />,
                    },
                ],
            },
            {
                path: "organisation",
                children: [
                    {
                        path: "details",
                        index: true,
                        element: (
                            <PermissionWrapper
                                permissions={[
                                    Claims.Company.UpdateRolePermissions,
                                ]}
                                outlet={<DashboardPages.organisation.details />}
                            />
                        ),
                    },
                    {
                        path: "team",
                        element: (
                            <PermissionWrapper
                                permissions={[
                                    Claims.Company.ManageUsers,
                                    Claims.Company.UpdateRoles,
                                ]}
                                outlet={<DashboardPages.organisation.team />}
                            />
                        ),
                    },
                    {
                        path: "supply-chain",
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.Company.ManageConnections]}
                                outlet={
                                    <DashboardPages.organisation.supplyChain />
                                }
                            />
                        ),
                    },
                    {
                        path: "data-sharing",
                        element: (
                            <PermissionWrapper
                                permissions={[Claims.Company.ManageGroups]}
                                outlet={
                                    <DashboardPages.organisation.dataSharingSettings />
                                }
                            />
                        ),
                    },
                ],
            },
        ],
    },
]);

export default AppRoutes;
